<template>
  <base-section id="about-us" space="0">
    <v-container class="fill-height px-4 py-12">
      <v-row>
        <v-col cols="12">
          <base-heading
            space="8"
            mobileSize="text-subtitle-1"
            size="text-subtitle-1"
            class="text-start"
            :title="description"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "AboutUsSection",

  provide: {
    heading: { align: "center" },
  },

  computed: {
    description() {
      return this.$route.meta.description;
    },
  },
};
</script>
